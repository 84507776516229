<template>
    <div>
        <div class="doc-type-wraps">
            <h2>固定分类</h2>
            <p>
                支持常规文档、图片、音频、视频、文件等多种格式类型数据进行分类查询并可以在线预览
            </p>
        </div>
        <div class="doc-type-list">
            <el-row :gutter="20">
                <el-col :span="6" v-for="(item,index) in dict.doc" :key="index" :class="{'colActived': index == activeInd}" @click.native="handleColEvent(index)">
                    <img src="@/assets/img/icon-picture.png" alt="" v-if="item.value == '1'">
                    <img src="@/assets/img/icon-audio.png" alt="" v-if="item.value == '2'">
                    <img src="@/assets/img/icon-vedio.png" alt="" class="vedio-pic" v-if="item.value == '3'">
                    <img src="@/assets/img/icon-word.png" alt="" v-if="item.value == '0'">
                    <h4>{{item.label}}</h4>
                    <p>{{item.remark}}</p>
                </el-col>
            </el-row>
        </div>
        <!-- <el-table
        :data="dict.doc"
        size="small"
        style="width: 100%;margin-top: 20px;">
            <el-table-column
            type="selection"
            align="center"
            width="80">
            </el-table-column>
            <el-table-column
                label="标签名"
                width="300">
                <template slot-scope="scope">
                    <div class="img-wrap">
                        <img src="@/assets/img/icon-picture.png" alt="" v-if="scope.row.value == '1'">
                        <img src="@/assets/img/icon-audio.png" alt="" v-if="scope.row.value == '2'">
                        <img src="@/assets/img/icon-vedio.png" alt="" class="vedio-pic" v-if="scope.row.value == '3'">
                        <img src="@/assets/img/icon-word.png" alt="" v-if="scope.row.value == '0'">
                        <span>{{scope.row.label}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="remark"
                label="简介"
                align="center">
            </el-table-column>
            <el-table-column
                prop="sort"
                label="排序"
                align="center">
            </el-table-column>
             <el-table-column 
                prop="state"
                label="状态"
                align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary" plain size="medium" v-if="scope.row.state == 0">正常</el-tag>
                        <el-tag type="info" plain size="medium" v-if="scope.row.state == 1">停用</el-tag>
                    </template>
            </el-table-column>
            <el-table-column
                label="修改日期"
                align="center">
                <template slot-scope="scope">
                    {{scope.row.updateDate | date}}
                </template>
            </el-table-column>
        </el-table> -->
    </div>
</template>
<script>
export default {
    data(){
        return {
            activeInd: 0
        }
    },
    computed: {
        dict() {
            return this.$store.state.dict.dicts;
        },
    },
    methods: {
        handleColEvent(index) {
            this.activeInd = index;
        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .img-wrap {
    display: flex;
    align-items: center;
    span {
        margin-left: 5px;
    }
    .vedio-pic {
        width: 24px;
        height: 24px;
    }
}
.doc-type-wraps {
    width: 100%;
    height: 280px;
    background-image: url('../../assets/type-banner.png');
    background-size: 100% 100%;
    h2 {
        padding: 50px 0 0 50px;
    }
    p {
        width: 500px;
        padding: 0 50px 0 50px;
        font-size: 16px;
        line-height: 28px;
    }
}
.doc-type-list {
    margin-top: 20px;
    .el-row {
        margin: 0px !important;
        .el-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 30px 0;
            cursor: pointer;
            img {
                width: 40px;
                height: 40px;
            }
            h4 {
                padding: 0;
                margin: 20px;
            }
            p {
                margin: 0;
                padding: 0;
            }
        }
        .colActived{
            background: #fff;
            border-radius: 10px;
            box-shadow: 0px 2px 16px 0px rgba(18, 55, 100, 0.2);
        }
    }
}
</style>